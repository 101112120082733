import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {UnoFilterBarComponent, UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {ReportTemplateFormat} from 'src/app/utils/report-template-format';
import {Environment} from 'src/environments/environment';
import {InspectionWorkflow} from 'src/app/models/inspections/workflow/inspection-workflow';
import {Inspection} from 'src/app/models/inspections/inspection/inspection';
import {Modal} from 'src/app/modal';
import {Locale} from 'src/app/locale/locale';
import {InputOptionsMultipleBatchRequest, InputOptionsMultipleLazyPageRequest} from 'src/app/components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {App} from '../../../../../app';
import {Session} from '../../../../../session';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {ServiceList} from '../../../../../http/service-list';
import {UUID} from '../../../../../models/uuid';
import {Resource} from '../../../../../models/resource';
import {QRGenerator} from '../../../../qr/data/qr-generator';
import {Service} from '../../../../../http/service';
import {InspectionWorkflowStep} from '../../../../../models/inspections/workflow/inspection-workflow-step';
import {InspectionProject} from '../../../../../models/inspections/project/inspection-project';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {UnoListComponent} from '../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {InspectionService} from '../../../services/inspection.service';
import {ResourceUtils} from '../../../../../utils/resource-utils';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';
import {InspectionEditPage} from '../edit/inspection-edit.page';
import {InspectionWorkflowService} from '../../../services/inspection-workflow.service';

@Component({
	selector: 'inspection-list-page',
	templateUrl: './inspection-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		TranslateModule,
		FormatDatePipe,
		UnoResponsiveTableListComponent,
		UnoFilterBarComponent,
		PermissionsPipe
	]
})
export class InspectionListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public settings = Session.settings;

	public resource: any = Resource;

	public generateQr = QRGenerator.generateFile;

	public app: any = App;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public selfStatic: any = InspectionListPage;

	public permissions = [UserPermissions.INSPECTION];

	/**
	 * Project UUID to list inspections for.
	 */
	public static project: UUID = null;

	/**
	 * Step UUID to list inspections for.
	 */
	public static step: UUID = null;

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 100;

	/**
	 * The number of items to show on table per page.
	 */
	public static tablePageSize: number = 30;

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'image', type: UnoTableColumnType.IMAGE, attribute: 'image', visible: true, size: 'small'},
		{header: 'assetName', type: UnoTableColumnType.TEXT, attribute: 'assetName', visible: true, size: 'small', sortBy: '[ap_asset].[name]'},
		{header: 'assetTag', type: UnoTableColumnType.TEXT, attribute: 'assetTag', visible: true, size: 'small', sortBy: '[ap_asset].[tag]'},
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', sortBy: '[inspection].[name]'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'medium', sortBy: '[inspection].[description]'},
		{header: 'step', type: UnoTableColumnType.TEXT, attribute: 'step', visible: true, size: 'small', sortBy: '[inspection_workflow_step].[name]'},
		{header: 'report', type: UnoTableColumnType.ICONS, attribute: 'report', visible: true, size: 'small'},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: true,
			size: 'small',
			icons:
			[
				{
					src: './assets/icons/assets/expand-icon.svg',
					click: (row): void => {
						App.openInTab('/menu/inspection/edit', {uuid: row.uuid});
					}
				}
			]
		}
	];

	/**
	 * Possible database filter to be used for ordering the inspections list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.DESC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[inspection].[updated_at]',
			options: [
				{label: 'name', value: '[inspection].[name]'},
				{label: 'description', value: '[inspection].[description]'},
				{label: 'updatedAt', value: '[inspection].[updated_at]'},
				{label: 'createdAt', value: '[inspection].[created_at]'},
				{label: 'step', value: '[inspection_workflow_step].[name]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'}
	
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[inspection].[name]', '[inspection].[description]', '[ap_asset].[name]', '[ap_asset].[tag]'],
			multiple: true,
			options: [
				{label: 'inspectionUuid', value: '[inspection].[id]'},
				{label: 'name', value: '[inspection].[name]'},
				{label: 'description', value: '[inspection].[description]'},
				{label: 'assetUuid', value: '[ap_asset].[id]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'step', value: '[inspection_workflow_step].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter list entries by their content.
		 */
		search: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: [],
		
		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',
		/**
		 * Steps to search by
		 */
		steps: []
	}, InspectionListPage.filterOptions);

	public static defaultFilters = structuredClone(InspectionListPage.filters);

	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const params = {
			project: InspectionListPage.project,
			stepUuids: InspectionListPage.step ? [InspectionListPage.step] : InspectionListPage.filters.steps,
			from: count,
			count: pageSize,
			search: InspectionListPage.filters.search,
			sortDirection: InspectionListPage.filters.sortDirection,
			sortField: InspectionListPage.filters.sortField,
			searchFields: InspectionListPage.filters.searchFields
		};

		const list = await InspectionService.list(params);

		const rowsData: any[] = [];
		for (const inspection of list.inspections) {
			const rowData = {
				uuid: inspection.uuid,
				name: inspection.name,
				description: inspection.description,
				image: inspection.asset?.pictures.length > 0 ? inspection.asset?.pictures[0] : './assets/placeholder/asset.png',
				assetName: inspection.asset?.name,
				assetTag: inspection.asset?.tag,
				step: inspection.step?.name,
				report: []
			};

			if (inspection.defaultReportTemplate) {
				rowData.report.push({
					src: './assets/icons/uno/checklist-new.svg',
					click: async(row): Promise<void> => {
						let workflow: InspectionWorkflow;
						if (!Environment.PRODUCTION || Environment.CLIENT_ID.startsWith('adn') || Environment.CLIENT_ID.startsWith('tratave')) {
							workflow = await InspectionWorkflowService.getFromProject(inspection.projectUuid);
						}

						const inspec: Inspection = await InspectionService.get(inspection.uuid);

						if (inspection.defaultReportTemplate.formats.includes(ReportTemplateFormat.PDF)) {
							try {
								await InspectionEditPage.exportReportPDF(inspection.defaultReportTemplate, inspec, workflow, true);
							} catch (e) {
								if (inspection.defaultReportTemplate.formats.includes(ReportTemplateFormat.DOCX)) {
									if (await Modal.confirm(Locale.get('error'), Locale.get('errorExportPDFConfirmTryDOCX'))) {
										await InspectionEditPage.exportReportDOCX(inspection.defaultReportTemplate, inspec, workflow);
									}
								} else {
									await Modal.alert(Locale.get('error'), Locale.get('errorExport'));
									console.error('EQS: Error exporting file.', e);
								}
							}
						} else if (inspection.defaultReportTemplate.formats.includes(ReportTemplateFormat.DOCX)) {
							try {
								await InspectionEditPage.exportReportDOCX(inspection.defaultReportTemplate, inspec, workflow);
							} catch (e) {
								await Modal.alert(Locale.get('error'), Locale.get('errorExport'));
								console.error('EQS: Error exporting file.', e);
							}
						}
					}
				});
			}

			rowsData.push(rowData);
		}

		return {
			elements: rowsData,
			hasMore: list.hasMore
		};
	};

	public async resetFilters(): Promise<void> {
		Object.assign(InspectionListPage.filters, InspectionListPage.defaultFilters);

		if (this.table) {
			await this.table.reset();
		}
	}

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		const data = App.navigator.getData();

		InspectionListPage.project = data?.project ? data.project : null;
		InspectionListPage.step = data?.step ? data.step : null;

		App.navigator.setTitle('list');

		if (InspectionListPage.filterOptions.filter((opt: any) => {return opt.attribute === 'steps';})) {
			const index: number = InspectionListPage.filterOptions.findIndex((opt: any) => {return opt.attribute === 'steps';});
			InspectionListPage.filterOptions.splice(index, 1);
		}

		if (InspectionListPage.step) {
			const request = await Service.fetch(ServiceList.inspection.workflowStep.get, null, null, {uuid: InspectionListPage.step}, Session.session, true);
			const step = InspectionWorkflowStep.parse(request.response.step);
			if (step.name) {
				App.navigator.setTitle(step.name);
			}
		} else if (!InspectionListPage.step) {
			InspectionListPage.filterOptions.push({
				type: UnoFilterBarOptionType.OPTIONS_LAZY,
				attribute: 'steps',
				label: 'steps',
				identifierAttribute: 'uuid',
				multiple: true,
				default: [],
				fetchOptionsLazy: async(request: InputOptionsMultipleLazyPageRequest): Promise<void> => {
					const params = {
						from: request.from,
						count: request.count,
						search: request.search,
						project: InspectionListPage.project
					};

					try {
						const req = await Service.fetch(ServiceList.inspection.workflowStep.list, null, null, params, Session.session);
						request.onFinish(req.response.steps, req.response.hasMore, req.id);
					} catch {
						request.onError();
					}
				},
				fetchOptionsBatch: async(request: InputOptionsMultipleBatchRequest): Promise<void> => {
					if (request.options.length > 0) {
						const req = await Service.fetch(ServiceList.inspection.workflowStep.getBatch, null, null, {stepUuids: request.options}, Session.session);
						request.onFinish(req.response.steps);
					}
					request.onFinish([]);
				},
				getOptionText: (option: any): string => {
					return (option.name ? option.name : '') + (option.description ? ' - ' + option.description : '');
				}
			});
		} else if (InspectionListPage.project) {
			const request = await Service.fetch(ServiceList.inspection.project.get, null, null, {uuid: InspectionListPage.project}, Session.session, true);
			const project = InspectionProject.parse(request.response.project);
			if (project.name) {
				App.navigator.setTitle(project.name);
			}
		}

		this.updateItemCount();
	}

	/**
	 * Update how many items available in the table
	 */
	public async updateItemCount(): Promise<void> {
		const params = {
			projectUuid: InspectionListPage.project,
			stepUuids: InspectionListPage.step ? [InspectionListPage.step] : InspectionListPage.filters.steps,
			search: InspectionListPage.filters.search,
			searchFields: InspectionListPage.filters.searchFields
		};

		this.tableTotalItemsCount = await InspectionService.count(params);
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		if (event.target === undefined) {
			InspectionListPage.filters.search = event;
		}

		if (this.table) {
			await this.table.reset();
		}

		await this.updateItemCount();
	}

	/**
	 * Update the filters and search term used.
	 *
	 * @param filters - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		InspectionListPage.filters = filters;

		this.table.sortDirection = InspectionListPage.filters.sortDirection;
		this.table.sortField = InspectionListPage.filters.sortField;

		if (this.table) {
			await this.table.reset();
		}

		await this.updateItemCount();
	}

	/**
	 * Navigate to the inspection form edit page.
	 *
	 * @param uuid - The UUID of the gap to open in new page.
	 */
	public async navigate(uuid): Promise<void> {
		await App.navigator.navigate('/menu/inspection/edit', {uuid: uuid});
	}

	/**
	 * When the table emits a value to change which column to sort by.
	 * 
	 * @param attribute - The attribute to sort by.
	 */
	public async sortChanged(attribute: string): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (attribute === InspectionListPage.filters.sortField) {
			InspectionListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			InspectionListPage.filters.sortField = attribute;
			InspectionListPage.filters.sortDirection = SortDirection.ASC;
		}
	
		if (this.table) {
			await this.table.reset();
		}
	}

	protected readonly ResourceUtils = ResourceUtils;
}
