import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle, KeyValuePipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UnoFilterBarComponent, UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {Locale} from 'src/app/locale/locale';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {RepairCriticalityLevelLabel} from 'src/app/models/repairs/repairs/repair-criticality';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {App} from '../../../../../app';
import {RepairInspectionStatus, RepairInspectionStatusLabel} from '../../../../../models/repairs/inspections/repair-inspection-status';
import {RepairInspectionResult, RepairInspectionResultLabel} from '../../../../../models/repairs/inspections/repair-inspection-result';
import {RepairCriticalityLabelPipe} from '../../../repair-work/pipes/repair-criticality-label.pipe';
import {RepairStatusLabelPipe} from '../../../repair-work/pipes/repair-status-label.pipe';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {UnoListComponent} from '../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {ResourceUtils} from '../../../../../utils/resource-utils';
import {RepairInspectionCountParams, RepairInspectionListParams, RepairInspectionListReturn, RepairInspectionService} from '../../services/repair-inspection.service';

@Component({
	selector: 'repair-inspection-list-page',
	templateUrl: 'repair-inspection-list.page.html',
	standalone: true,
	imports: [
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		KeyValuePipe,
		TranslateModule,
		FormatDatePipe,
		RepairStatusLabelPipe,
		RepairCriticalityLabelPipe,
		UnoFilterBarComponent,
		UnoResponsiveTableListComponent
	]
})
export class RepairInspectionListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public app: any = App;

	public selfStatic: any = RepairInspectionListPage;

	public permissions = [UserPermissions.REPAIR_INSPECTIONS];

	/**
	 * Status of inspection.
	 */
	public status: number = 0;

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 0;

	/**
	 * The number of items to show on table per page.
	 */
	public tablePageSize: number = 30;

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'image', type: UnoTableColumnType.IMAGE, attribute: 'image', visible: this.headerActive('image'), size: 'small'},
		{header: 'status', type: UnoTableColumnType.TEXT, attribute: 'status', visible: this.headerActive('status'), size: 'small'},
		{header: 'assetName', type: UnoTableColumnType.TEXT, attribute: 'assetName', visible: this.headerActive('assetName'), size: 'small', sortBy: '[ap_asset].[name]'},
		{header: 'assetTag', type: UnoTableColumnType.TEXT, attribute: 'assetTag', visible: this.headerActive('assetTag'), size: 'small', sortBy: '[ap_asset].[tag]'},
		{header: 'dueDate', type: UnoTableColumnType.DATE, attribute: 'dueDate', visible: this.headerActive('dueDate'), size: 'small', sortBy: '[repair_inspection].[due_date]'},
		{header: 'date', type: UnoTableColumnType.DATE, attribute: 'date', visible: this.headerActive('date'), size: 'small', sortBy: '[repair_inspection].[date]'},
		{header: 'updatedAt', type: UnoTableColumnType.DATE, attribute: 'updatedAt', visible: this.headerActive('updatedAt'), size: 'small', sortBy: '[repair_inspection].[updated_at]'},
		{header: 'criticality', type: UnoTableColumnType.TEXT, attribute: 'criticality', visible: this.headerActive('criticality'), size: 'small'},
		{header: 'result', type: UnoTableColumnType.TEXT, attribute: 'result', visible: this.headerActive('result'), size: 'small'},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: this.headerActive('actions'),
			size: 'medium',
			icons: [
				{
					src: './assets/icons/assets/expand-icon.svg',
					click: (row): void => {
						App.openInTab('/menu/repairs/inspections/edit', {uuid: row.uuid});
					} 
				}]
		}
	];

	/**
	 * Possible database filter to be used for ordering the Repair Inspection list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.DESC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[repair_inspection].[updated_at]',
			options: [
				{label: 'updatedAt', value: '[repair_inspection].[updated_at]'},
				{label: 'createdAt', value: '[repair_inspection].[created_at]'},
				{label: 'date', value: '[repair_inspection].[date]'},
				{label: 'dueDate', value: '[repair_inspection].[due_date]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'},
				{label: 'criticality', value: '[repair_inspection].[criticality]'},
				{label: 'result', value: '[repair_inspection].[result]'},
				{label: 'status', value: '[repair_inspection].[status]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[ap_asset].[name]', '[ap_asset].[tag]', '[repair_inspection].[id]', '[repair_inspection].[repair_id]', '[repair].[repair_order]', '[repair_inspection].[inspection_number]'],
			options: [
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'},
				{label: 'repairOrder', value: '[repair].[repair_order]'},
				{label: 'uuid', value: '[repair_inspection].[id]'},
				{label: 'assetUuid', value: '[ap_asset].[id]'},
				{label: 'repairUuid', value: '[repair_inspection].[repair_id]'},
				{label: 'inspectionNumber', value: '[repair_inspection].[inspection_number]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'resultField',
			label: 'result',
			default: [RepairInspectionResult.NONE, RepairInspectionResult.COMPLIANT, RepairInspectionResult.NON_COMPLIANT],
			options: [
				{label: 'none', value: RepairInspectionResult.NONE},
				{label: 'compliant', value: RepairInspectionResult.COMPLIANT},
				{label: 'nonCompliant', value: RepairInspectionResult.NON_COMPLIANT}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'tableFields',
			label: 'tableFields',
			default: ['image', 'assetName', 'assetTag', 'status', 'date', 'actions', 'criticality'],
			options: [
				{label: 'image', value: 'image'},
				{label: 'assetName', value: 'assetName'},
				{label: 'assetTag', value: 'assetTag'},
				{label: 'status', value: 'status'},
				{label: 'dueDate', value: 'dueDate'},
				{label: 'date', value: 'date'},
				{label: 'updatedAt', value: 'updatedAt'},
				{label: 'criticality', value: 'criticality'},
				{label: 'result', value: 'result'},
				{label: 'actions', value: 'actions'}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter repair inspection by their name.
		 */
		search: '',

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: [],

		/**
		 * Filter by inspection result
		 */
		resultField: []
	}, RepairInspectionListPage.filterOptions);

	public static defaultFilters = structuredClone(RepairInspectionListPage.filters);

	public resetFilters(): void {
		Object.assign(RepairInspectionListPage.filters, RepairInspectionListPage.defaultFilters);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.status = RepairInspectionStatus.ALL;

		// Read status from the route data
		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		this.status = data.status !== undefined ? data.status : RepairInspectionStatus.ALL;

		if (this.status !== RepairInspectionStatus.ALL) {
			App.navigator.setTitle(RepairInspectionStatusLabel.get(data.status));
		} else {
			App.navigator.setTitle('list');
		}
	}


	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const params: RepairInspectionListParams = {
			status: this.status,
			from: count,
			count: pageSize,
			search: RepairInspectionListPage.filters.search,
			sortDirection: RepairInspectionListPage.filters.sortDirection,
			sortField: RepairInspectionListPage.filters.sortField,
			searchFields: RepairInspectionListPage.filters.searchFields,
			resultField: RepairInspectionListPage.filters.resultField
		};

		const list: RepairInspectionListReturn = await RepairInspectionService.list(params);
		const inspections: any[] = list.inspections;

		inspections.forEach((inspection) => {
			inspection.image = inspection.pictures.length > 0 ? inspection.pictures[0] : './assets/placeholder/asset.png';
			inspection.criticality = Locale.get(RepairCriticalityLevelLabel.get(inspection.repair.criticality));
			inspection.result = Locale.get(RepairInspectionResultLabel.get(inspection.result));
			inspection.assetName = inspection.asset.name;
			inspection.assetTag = inspection.asset.tag;
			inspection.status = Locale.get(RepairInspectionStatusLabel.get(inspection.status));
		});

		return {
			elements: list.inspections,
			hasMore: list.hasMore
		};
	};

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value
	 */
	public onSearchChange(search: string): void {
		RepairInspectionListPage.filters.search = search;
		this.reset();
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param event - DOM event.
	 */
	public onFilterChange(filters: any): void {
		RepairInspectionListPage.filters = filters;
		this.reset();
	}

	/**
	 * Reset the table.
	 */
	public async reset(): Promise<void> {
		if (this.table) {
			await this.table.reset();
		}

		const params: RepairInspectionCountParams = {
			status: this.status,
			search: RepairInspectionListPage.filters.search,
			searchFields: RepairInspectionListPage.filters.searchFields,
			resultField: RepairInspectionListPage.filters.resultField
		};

		this.table.sortDirection = RepairInspectionListPage.filters.sortDirection;
		this.table.sortField = RepairInspectionListPage.filters.sortField;

		this.tableLayout.forEach((column) => {
			column.visible = this.headerActive(column.attribute);
		});

		this.tableTotalItemsCount = await RepairInspectionService.count(params);
	}

	/**
	 * When the user changes the sort using the table
	 * 
	 * @param sortBy - Attribute to sort by.
	 */
	public async sortChanged(sortBy: any): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === RepairInspectionListPage.filters.sortField) {
			RepairInspectionListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			RepairInspectionListPage.filters.sortField = sortBy;
			RepairInspectionListPage.filters.sortDirection = SortDirection.ASC;
		}

		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * Check if a table header is active
	 * 
	 * @param attribute - The attribute to check if is active.
	 * @returns True if the header is active
	 */
	public headerActive(attribute: string): boolean {
		return RepairInspectionListPage.filters.tableFields.indexOf(attribute) !== -1;
	}


	protected readonly ResourceUtils = ResourceUtils;
}


