import {SortDirection} from 'src/app/utils/sort-direction';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {Session} from '../../../../session';
import {APAssetFormBlockFieldComponentType, APAssetFormBlockFieldComponentTypeLabel} from '../../../../models/asset-portfolio/asset-form-block-field-type';

/**
 * Layout of the form to edit FormBlockField entries.
 */
export const AssetFormBlockFieldLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		attribute: 'required',
		label: 'required',
		type: UnoFormFieldTypes.CHECKBOX
	},
	{
		required: true,
		label: 'fieldType',
		attribute: 'formFieldComponent',
		type: UnoFormFieldTypes.OPTIONS,
		isEmpty: function(object) {
			return !object.formFieldComponent || object.formFieldComponent === APAssetFormBlockFieldComponentType.NONE;
		},
		options: Object.values(APAssetFormBlockFieldComponentType).map(function(value) {
			return {value: value, label: APAssetFormBlockFieldComponentTypeLabel.get(value)};
		}),
		sort: true
	},
	{
		required: false,
		label: 'options',
		attribute: 'data',
		type: UnoFormFieldTypes.TEXT_LIST,
		isActive: function(object, row) {
			return object.formFieldComponent === APAssetFormBlockFieldComponentType.OPTIONS || object.formFieldComponent === APAssetFormBlockFieldComponentType.OPTIONS_MULTIPLE;
		}
	}
];

/**
 * Layout of the form to edit form block entries.
 */
export const APAssetFormBlockLayout: UnoFormField[] = [
	{
		label: 'formBlock',
		type: UnoFormFieldTypes.TITLE
	},
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	}
];

/**
 * Layout of the form tab card to edit FormTabCard entries.
 */
export const AssetFormTabCardLayout: UnoFormField[] = [
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: true,
		attribute: 'usesPrivateBlock',
		label: 'useFormBlock',
		type: UnoFormFieldTypes.CHECKBOX,
		invert: true,
		isEmpty: function(object) {
			return object.usesPrivateBlock && (!object.block || !object.block.fields || object.block.fields.length === 0) ;
		}
	},
	{
		required: true,
		attribute: 'blockUuid',
		label: 'formBlock',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		identifierAttribute: 'uuid',
		multiple: false,
		isActive: function(object) {
			return !object.usesPrivateBlock;
		},
		fetchOptionsLazy: async function(request: any): Promise<void> {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				sortField: '[ap_form_block].[name]',
				sortDirection: SortDirection.ASC
			};

			try {
				const req = await Service.fetch(ServiceList.assetPortfolio.formBlock.listName, null, null, data, Session.session);
				request.onFinish(req.response.blocks, req.response.hasMore, request.id);
			} catch {
				request.onError();
			}
		},
		fetchOptionsBatch: async function(request: any): Promise<void> {
			const data = {blocks: request.options};

			try {
				const req = await Service.fetch(ServiceList.assetPortfolio.formBlock.getBatch, null, null, data, Session.session);
				request.onFinish(req.response.blocks);
			} catch {
				request.onError();
			}
		},
		getOptionText: function(option: any): string {
			return option.name;
		}
	}
];

/**
 * Layout of the form tab to edit FormTab entries.
 */
export const APAssetFormTabLayout: UnoFormField[] = [
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	}
];

