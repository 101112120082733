import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {UnoFilterBarComponent, UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {UUID} from 'src/app/models/uuid';
import {ServiceSync} from '../../../../../http/service-sync';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {Resource} from '../../../../../models/resource';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {Session} from '../../../../../session';
import {QRGenerator} from '../../../../qr/data/qr-generator';
import {UnoListLazyLoadHandler} from '../../../../../components/uno/uno-list/uno-list-lazy-load-handler';
import {APAssetExport} from '../../../data/asset-export';
import {UnoListComponent} from '../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {ResourceUtils} from '../../../../../utils/resource-utils';
import {AssetListParams, AssetListResponse, AssetService} from '../../../services/asset.service';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

@Component({
	selector: 'assets-list-page',
	templateUrl: 'assets-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		TranslateModule,
		UnoResponsiveTableListComponent,
		UnoFilterBarComponent,
		PermissionsPipe
	]
})
export class AssetsListPage extends ScreenComponent implements OnInit {
	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public settings = Session.settings;

	public app: any = App;

	public resource: any = Resource;

	public generateQr = QRGenerator.generateFile;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public selfStatic: any = AssetsListPage;

	public permissions = [UserPermissions.ASSET_PORTFOLIO];

	/**
	 * Asset type name to display on the top bar.
	 */
	@Input()
	public name: string = null;

	/**
	 * Lazy list loading handler.
	 */
	public handler: UnoListLazyLoadHandler<any> = new UnoListLazyLoadHandler<any>();

	/**
	 * Object to synchronize service requests.
	 */
	public serviceSync: ServiceSync = new ServiceSync();

	/**
	 * Type of assets being displayed on this page. Passed to the API as argument.
	 */
	@Input()
	public typeUuid: UUID = null;

	/**
	 * The maximum number of items to show on table component.
	 */
	public totalItems: number = 1;

	/**
	 * The number of items to show on table per page.
	 */
	public tablePageSize: number = 30;

	/**
	 * The layout to use on the Uno Table component.
	 */
	public layout: UnoTableColumnLayout[] = [
		{header: 'image', type: UnoTableColumnType.IMAGE, attribute: 'image', visible: true, size: 'small'},
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', sortBy: '[ap_asset].[name]'},
		{header: 'tag', type: UnoTableColumnType.TEXT, attribute: 'tag', visible: true, size: 'small', sortBy: '[ap_asset].[tag]'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'small'},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: true,
			size: 'small',
			iconNumber: 2,
			icons: [
				{
					src: './assets/components/menu/qrcode.svg',
					click: (row: any): void => {
						this.generateQr(row.uuid);
					}
				},
				{
					src: './assets/icons/assets/expand-icon.svg',
					click: (row: any): void => {
						App.openInTab('/menu/asset-portfolio/asset/edit', {uuid: row.uuid});
					}
				}
			]
		}
	];

	/**
	 * Possible database filter to be used for ordering the Asset list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[ap_asset].[updated_at]',
			options: [
				{label: 'updatedAt', value: '[ap_asset].[updated_at]'},
				{label: 'createdAt', value: '[ap_asset].[created_at]'},
				{label: 'tag', value: '[ap_asset].[tag]'},
				{label: 'name', value: '[ap_asset].[name]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[ap_asset].[name]', '[ap_asset].[tag]', '[ap_asset].[description]'],
			multiple: true,
			options: [
				{label: 'name', value: '[ap_asset].[name]'},
				{label: 'tag', value: '[ap_asset].[tag]'},
				{label: 'description', value: '[ap_asset].[description]'},
				{label: 'assetUuid', value: '[ap_asset].[id]'}
			]
		}
	];
		
	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Text used to filter assets by their name, description or UUID.
		 */
		search: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: []
	}, AssetsListPage.filterOptions);

	public loadMore = async(count: number, pageSize: number): Promise<any> => {
		const params: AssetListParams = {
			search: AssetsListPage.filters.search,
			sortDirection: AssetsListPage.filters.sortDirection,
			sortField: AssetsListPage.filters.sortField,
			searchFields: AssetsListPage.filters.searchFields,
			typeUuid: this.typeUuid,
			from: count,
			count: pageSize 
		};

		const list: AssetListResponse = await AssetService.list(params);

		const assets: any[] = list.assets;

		for (const asset of assets) {
			asset.image = asset.pictures[0];
		}

		return {
			elements: assets,
			hasMore: list.hasMore
		};
	};

	public ngOnInit(): void {
		super.ngOnInit();

		const data = App.navigator.getData();

		if (data?.typeUuid) {
			this.typeUuid = data.typeUuid;
		}

		App.navigator.setTitle(data?.name || 'list');
	}

	public static defaultFilters = structuredClone(AssetsListPage.filters);

	/**
	 * Exports assets applying the list filters to a XLSX file.
	 */
	public async exportXLSX(): Promise<void> {
		await APAssetExport.exportXLSX(false, AssetsListPage.filters.typeUuid, null, AssetsListPage.filters.search, AssetsListPage.filters.searchFields, AssetsListPage.filters.sortField, AssetsListPage.filters.sortDirection);
	}

	public resetFilters(): void {
		this.serviceSync.reset();

		Object.assign(AssetsListPage.filters, AssetsListPage.defaultFilters);
	}

	/**
	 * Update filters and reload data from the API.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		AssetsListPage.filters = filters;
	
		this.table.sortDirection = AssetsListPage.filters.sortDirection;
		this.table.sortField = AssetsListPage.filters.sortField;

		this.totalItems = await AssetService.count({
			search: AssetsListPage.filters.search,
			searchFields: AssetsListPage.filters.searchFields,
			typeUuid: this.typeUuid
		});

		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		AssetsListPage.filters.search = event;

		this.totalItems = await AssetService.count({
			search: AssetsListPage.filters.search,
			searchFields: AssetsListPage.filters.searchFields,
			typeUuid: this.typeUuid
		});

		if (this.table) {
			await this.table.reset();
		}
	}

	public async sortChanged(sortBy: any): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === AssetsListPage.filters.sortField) {
			AssetsListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			AssetsListPage.filters.sortField = sortBy;
			AssetsListPage.filters.sortDirection = SortDirection.ASC;
		}

		if (this.table) {
			await this.table.reset();
		}
	}

	protected readonly ResourceUtils = ResourceUtils;
}
