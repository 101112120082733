import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {ListDisplayStyle, UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {UnoFilterBarComponent, UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {UUID} from 'src/app/models/uuid';
import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {UnoListItemLabelComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoNoDataComponent} from '../../../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {UnoSearchbarComponent} from '../../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {LDSListResponse, LDSService} from '../../../../services/lds.service';

@Component({
	selector: 'lds-list-page',
	templateUrl: 'lds-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoContentComponent,
		NgStyle,
		UnoNoDataComponent,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		TranslateModule,
		UnoResponsiveTableListComponent,
		UnoFilterBarComponent
	]
})
export class LDSListPage extends ScreenComponent implements OnInit {
	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public app: any = App;

	public session: any = Session;

	public selfStatic: any = LDSListPage;

	/**
	 * The current pipeline UUid.
	 */
	public pipelineUuid: UUID = '';

	/**
	 * The maximum number of items to show on table component.
	 */
	public totalItems: number = 1;

	/**
	 * The number of items to show on table per page.
	 */
	public tablePageSize: number = 30;

	public ngOnInit(): void {
		super.ngOnInit();

		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}
		
		App.navigator.setTitle('lds');

		this.pipelineUuid = data.pipelineUuid;

		this.resetTable();
	}

	/**
	 * The layout to use on the Uno Table component.
	 */
	public layout: UnoTableColumnLayout[] = [
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', sortBy: 'name', tag: ListDisplayStyle.TITLE},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'small', sortBy: 'description', tag: ListDisplayStyle.TEXT},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: true,
			size: 'small',
			icons: [
				{
					src: './assets/pipeline-integrity/graph-icon.svg',
					click: (row): void => {
						App.navigator.navigate('/menu/pipeline-integrity/lds/chart', {ldsUuid: row.uuid});
					}
				}
			]
		}
	];

	/**
	 * Possible database filter to be used for ordering the LDS list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: 'updated_at',
			options: [
				{label: 'updatedAt', value: 'updated_at'},
				{label: 'createdAt', value: 'created_at'},
				{label: 'name', value: 'name'},
				{label: 'description', value: 'description'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['name', 'description'],
			multiple: true,
			options: [
				{label: 'uuid', value: 'uuid'},
				{label: 'name', value: 'name'},
				{label: 'description', value: 'description'}
			]
		}
	];
	
	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Sort direction applied to the loaded list from database.
		 */
		 sortDirection: '',

		 /**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Text used to filter cmps by their name, description or UUID.
		 */
		search: '',

		/**
		 * Search fields to be considered(name should match database column)
		 */
		 searchFields: []
	}, LDSListPage.filterOptions);

	public loadMore = async(count: number, pageSize: number): Promise<any> => {
		const list: LDSListResponse = await LDSService.list({
			pipelineUuid: this.pipelineUuid,
			filters: {
				sortField: LDSListPage.filters.sortField,
				sortDirection: LDSListPage.filters.sortDirection,
				search: LDSListPage.filters.search,
				searchFields: LDSListPage.filters.searchFields,
				offset: count,
				count: pageSize
			}
		});

		const ldss: any[] = list.lds;
	
		return {
			elements: ldss,
			hasMore: list.hasMore
		};
	};

	/**
	 * Update filters and reload data from the API.
	 *
	 * @param event - DOM event.
	 */
	 public onFilterChange(filters: any): void {
		LDSListPage.filters = filters;
	
		this.table.sortDirection = LDSListPage.filters.sortDirection;
		this.table.sortField = LDSListPage.filters.sortField;

		this.resetTable();
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public onSearch(event: any): void {
		LDSListPage.filters.search = event;

		this.resetTable();
	}

	/**
	 * Update the sorting on the page.
	 *
	 * @param sortBy - Sort direction to change to.
	 */
	public async sortChanged(sortBy: any): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === LDSListPage.filters.sortField) {
			LDSListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			LDSListPage.filters.sortField = sortBy;
			LDSListPage.filters.sortDirection = SortDirection.ASC;
		}

		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * Reset the table and count.
	 */
	public async resetTable(): Promise<void> {
		this.totalItems = await LDSService.count({
			pipelineUuid: this.pipelineUuid,
			filters: {
				search: LDSListPage.filters.search,
				searchFields: LDSListPage.filters.searchFields
			}
		});

		if (this.table) {
			await this.table.reset();
		}
	}
}
