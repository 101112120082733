import {APAsset} from 'src/app/models/asset-portfolio/asset';
import {UUID} from '../../../../models/uuid';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {AtexInspection} from '../../../../models/atex-inspections/inspections/atex-inspection';

export type AtexInspectionHistory = {
	// ID of the history entry
	id: number,
	// History action (create, update)
	action: number,
	// History date
	date: Date,
	// User that performed change
	user: {
		// ID of the user
		uuid: UUID,
		// Name
		name: string,
		// Email
		email: string,
		// Phone number
		phone: string
	}
}

export type AtexInspectionList = {
	// UUID of the atex inspection.
	uuid: UUID,
	// Asset of the atex inspection.
	asset: {name: string, tag: string, pictures: any[], parent: APAsset},
	// Result of the atex inspection.
	result: number,
	// Status of the atex inspection.
	status: number,
	// When the atex inspection was updated.
	updatedAt: string
}

export type AtexInspectionListParams = {
	// Status of the atex inspection.
	status?: number,
	// Search value to apply on the request
	search?: string,
	// Fields to search by on the request
	searchFields?: string[],
	// Where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort direction to apply on the request
	sortDirection?: string,
	// Field to sort by
	sortField?: string,
	// If the atex inspection is awaiting documents
	filterWaitingDocuments?: number,
	// Results of the atex inspection
	results?: number[],
	// The subtype of the atex inspection
	filterSubtype?: UUID,
	// If the atex inspection has no pictures
	noPictures?: boolean
};

export type AtexInspectionCountParams = {
	// Status of the atex inspection.
	status?: number,
	// Search value to apply on the request
	search?: string,
	// Fields to search by on the request
	searchFields?: string[],
	// If the atex inspection is awaiting documents
	waitingDocuments?: number,
	// Results of the atex inspection
	results?: number[],
	// The subtype of the atex inspection
	subtype?: UUID
};

export class AtexInspectionService {
	/**
	 * Get an Atex inspection by its UUID.
	 *
	 * @param uuid - UUID of the inspection.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * 
	 * @returns The Atex inspection that matches the UUID.
	 */
	public static async get(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<AtexInspection> {
		const request = await Service.fetch(ServiceList.atex.inspection.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return AtexInspection.parse(request.response.inspection);
	}

	/**
	 * List history entries for Atex inspection.
	 *
	 * @param uuid - uuid of the Atex inspection to load the history of.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * 
	 * @returns The Atex inspection history entries that match the params.
	 */
	public static async listHistoryUsers(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<AtexInspectionHistory[]> {
		const response = await Service.fetch(ServiceList.atex.inspection.historyListUsers, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return response.response.history;
	}

	/**
	 * List all Atex Inspections meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * 
	 * @returns Array of Atex inspections that match the params.
	 */
	public static async list(params: AtexInspectionListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<{hasMore: boolean, inspections: AtexInspectionList[], id: number}> {
		const request = await Service.fetch(ServiceList.atex.inspection.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			inspections: request.response.inspections,
			id: request.id
		};
	}

	/**
	 * Count all Atex Inspections meeting the parameters.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * 
	 * @returns Number of Atex inspections that match the params.
	 */
	public static async count(params: AtexInspectionCountParams, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		return (await Service.fetch(ServiceList.atex.inspection.count, null, null, params, Session.session, hideLoading, displayError)).response.count;
	}
}
