export const Environment = {
	PRODUCTION: true,
	CLIENT_ID: 'repsol',
	DEFAULT_ROUTE: null,
	TEST: false,
	VERSION: '0.345.25',
	MODULES: [
		'atex-inspections',
		'digital-twin',
		'asset-planning',
		'gap-analysis',
		'inspections',
		'observations',
		'epi',
		'repairs'
	],
	TIMESTAMP: '2024-10-10T23:25:34.356Z',
	COMMIT: 'c7c8170c505aa6feb71169df5df2575b09e2f826',
	BRANCH: 'task/AM-3077-last-repair-inspection-result',
	API_SERVER: 'https://repsol-api.unoplatform.io',
	MAPBOX_TOKEN: 'pk.eyJ1IjoidW5vM2QiLCJhIjoiY2swbWk3ZDU1MTQ2aTNubXprbnZ6Y2c1bCJ9.fGhQreB4AkAUcW_5_tr81Q',
	RESOURCE_SERVER: 'https://repsol-resources.unoplatform.io',
	FILE_CONVERTER_SERVER: 'https://file-converter.unoplatform.io',
	PIPELINE_INTEGRITY_SERVER: null,
	DIGITAL_TWIN_SERVER: null
};
